import './SeasonFilter.css';

export default function SeasonFilter({ seasons, setyear, currentYear }) {
    return (
        <>
            <h2>Seizoenen</h2>
            <ul className="seasonfilter">
                {seasons.map((year, index) => (
                    <li key={index}><button className={`${currentYear === year ? 'active' : ''} seasonfilter--button`} onClick={() => setyear(year)}>{year}</button></li>
                ))}
            </ul>
        </>
    );
}