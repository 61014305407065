import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import './FunStats.css';

import data from '../data/new_data.json';

function FunStats() {

    const [mostPlayed, setMostPlayed] = useState([]);
    const [mostPlayedGenres, setMostPlayedGenres] = useState([]);
    const [longestTracks, setLongestTracks] = useState([]);
    const [shortestTracks, setShortestTracks] = useState([]);
    const [totalAmountTracks, setTotalAmountTracks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        var trackList = [];

        data.years.map((year) => year.episodes.map((episode) => episode.tracks.map((track) => trackList.push(track))));

        let numLolTracks = trackList.reduce(function (n, track) {
            return n + (track.type === 'LOL');
        }, 0);

        let numBonusTracks = trackList.reduce(function (n, track) {
            return n + (track.type === 'BONUS');
        }, 0);

        let numRegularTracks = trackList.reduce(function (n, track) {
            return n + (track.type === 'ZLT');
        }, 0);

        setTotalAmountTracks([{
            "type": 'Regulier',
            "amount": numRegularTracks
        },
        {
            "type": "Petje.af / LOL / Bonus",
            "amount": numLolTracks + numBonusTracks
        }]);

        // Count unique artists
        let uniqueArtistCount = trackList.reduce(function (sums, entry) {
            sums[entry.artist_name] = (sums[entry.artist_name] || 0) + 1;
            return sums;
        }, {});

        let artistSort = Object.entries(uniqueArtistCount).sort((a, b) => (a[1] > b[1]) ? -1 : 1);
        setMostPlayed(artistSort.slice(0, 10));

        // Count unique genres
        let uniqueGenreCount = trackList.reduce(function (sums, entry) {
            sums[entry.genre] = (sums[entry.genre] || 0) + 1;
            return sums;
        }, {});

        let genreSort = Object.entries(uniqueGenreCount).sort((a, b) => (a[1] > b[1]) ? -1 : 1);
        setMostPlayedGenres(genreSort.slice(0, 10));

        trackList.sort((a, b) => a.time_ms - b.time_ms).reverse();
        setLongestTracks(trackList.slice(0, 5));

        trackList.sort((a, b) => a.time_ms - b.time_ms);
        setShortestTracks(trackList.slice(0, 5));



        setLoading(false);

    }, []);


    const secondsToMinutesAndSeconds = (millis) => {
        const minutes = Math.floor(millis / 60);
        const seconds = millis - minutes * 60;
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }

    if (loading) {
        return (
            <div>
                <Helmet>
                    <title>De Zes Losse Tanden Database - Leuke statistieken</title>
                    <meta name="description" content="Een overzicht met leuke statistieken over afspeeltijd, meest meegebrachte bands en meer!" />
                </Helmet>

                <div className="app--container">

                    <h1>Zoeken</h1>

                    <div className="card--grid--stats">
                        <div>
                            <h2>10 meest voorkomende bands</h2>

                            <div className="tablecontainer">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Band</th>
                                            <th>Hoe vaak</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div>
                            <h2>10 meest gespeelde genres</h2>

                            <div className="tablecontainer">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Genre</th>
                                            <th>Hoe vaak</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div>
                            <h2>Langste nummers</h2>

                            <div className="tablecontainer">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Band</th>
                                            <th>Track</th>
                                            <th>Door</th>
                                            <th>Tijd</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>...</td><td>...</td><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td><td>...</td><td>...</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div>
                            <h2>Kortste nummers</h2>

                            <div className="tablecontainer">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Band</th>
                                            <th>Track</th>
                                            <th>Door</th>
                                            <th>Tijd</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>...</td><td>...</td><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td><td>...</td><td>...</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div>
                            <h2>Totaal aantal tracks</h2>

                            <div className="tablecontainer">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Regulier</th>
                                            <th>LOL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>...</td><td>...</td></tr>
                                        <tr><td>...</td><td>...</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        );
    }


    return (
        <div>
            <Helmet>
                <title>De Zes Losse Tanden Database - Leuke statistieken</title>
                <meta name="description" content="Een overzicht met leuke statistieken over afspeeltijd, meest meegebrachte bands en meer!" />
            </Helmet>

            <div className="app--container">

                <h1>Statistieken</h1>

                <div className="card--grid--stats">
                    <div>
                        <h2>10 meest voorkomende bands</h2>

                        <div className="tablecontainer">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Band</th>
                                        <th>Hoe vaak</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mostPlayed.map((artist, index) => (
                                        <tr key={index}>
                                            <td>{artist[0]}</td>
                                            <td>{artist[1]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h2>10 meest gespeelde genres</h2>

                        <div className="tablecontainer">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Genre</th>
                                        <th>Hoe vaak</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mostPlayedGenres.map((genre, index) => (
                                        <tr key={index}>
                                            <td><Link to={`/genre/${genre[0]}`}>{genre[0]}</Link></td>
                                            <td>{genre[1]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h2>Langste nummers</h2>

                        <div className="tablecontainer">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Band</th>
                                        <th>Track</th>
                                        <th>Door</th>
                                        <th>Tijd</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {longestTracks.map((track, index) => (
                                        <tr key={index}>
                                            <td>{track.artist_name}</td>
                                            <td>{track.track_name}</td>
                                            <td>{track.who}</td>
                                            <td>{secondsToMinutesAndSeconds(track.time_ms)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h2>Kortste nummers</h2>

                        <div className="tablecontainer">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Band</th>
                                        <th>Track</th>
                                        <th>Door</th>
                                        <th>Tijd</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shortestTracks.map((track, index) => (
                                        <tr key={index}>
                                            <td>{track.artist_name}</td>
                                            <td>{track.track_name}</td>
                                            <td>{track.who}</td>
                                            <td>{secondsToMinutesAndSeconds(track.time_ms)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h2>Totaal aantal tracks</h2>

                        <div className="tablecontainer">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Type aflevering</th>
                                        <th>Aantal tracks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {totalAmountTracks.map((type, index) => (
                                        <tr key={index}><td>{type.type}</td><td>{type.amount}</td></tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
}

export default FunStats;
