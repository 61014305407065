import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function FinalEpisode({ episode }) {

    return (
        <div>
            <h2>Laatste aflevering <small className={`final-episode--type ${episode?.type === "LOL" ? 'lol' : ''}`}>{episode?.type === "ZLT" ? "Regulier" : episode?.type}</small></h2>

            <div className="tablecontainer">
                <table>
                    <thead>
                        <tr>
                            <th width="16"></th>
                            <th>Band</th>
                            <th>Track</th>
                            <th>Door</th>
                            <th>Genre</th>
                        </tr>
                    </thead>
                    <tbody>
                        {episode?.tracks.map((episode, index) => (
                            <tr key={index}>
                                <td>{episode.artist_country &&
                                    <LazyLoadImage
                                        className="artist_flag"
                                        alt={`Flag icon for ${episode.artist_country}`}
                                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${episode.artist_country}.svg`} />
                                }</td>
                                <td>{episode.artist_name}</td>
                                <td>{episode.track_name}</td>
                                <td><Link to={`/presenter/${episode.who}`}>{episode.who}</Link></td>
                                <td><Link to={`/genre/${episode.genre}`}>{episode.genre}</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default FinalEpisode;