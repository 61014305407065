import { Helmet } from 'react-helmet-async';

import './YearOverview.css';


function YearOverview() {

    return (
        <div>
            <Helmet>
                <title>De Zes Losse Tanden Database - Jaarlijstjes</title>
                <meta name="description" content="Een door de luisteraars samengestelde jaarlijst van 2023 " />
            </Helmet>

            <div className="app--container">

                <h1>Jaarlijstjes 2023</h1>

                <p>Een paar mooie statistieken voor 2023, samengesteld door Luuk.</p>

                <div className="card--grid--stats">
                    <div>
                        <h2>Top 20 beste releases</h2>

                        <div className="tablecontainer">
                            <table>
                                <thead>
                                    <tr>
                                        <th width="20">#</th>
                                        <th>Band</th>
                                        <th>Album</th>
                                        <th>Punten</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>1</td>
                                        <td>John Coffey</td>
                                        <td>Four</td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Sleep Token</td>
                                        <td>Take Me Back To Eden</td>
                                        <td>59</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Knocked Loose</td>
                                        <td>Upon Loss</td>
                                        <td>33</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Spiritbox </td>
                                        <td>The Fear Of Fear</td>
                                        <td>31</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Polaris</td>
                                        <td>Fatalism</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Dødheimsgard</td>
                                        <td>Black Medium Current</td>
                                        <td>28</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Better Lovers</td>
                                        <td>God Made Me An Animal</td>
                                        <td>27</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>Ahab</td>
                                        <td>The Coral Tombs</td>
                                        <td>26</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Blood Abscission</td>
                                        <td>I</td>
                                        <td>26</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>Baroness</td>
                                        <td>Stone</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Silent Planet</td>
                                        <td>Superbloom</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Svalbard</td>
                                        <td>The Weight Of The Mask</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>Drain</td>
                                        <td>Living Proof</td>
                                        <td>23</td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td>The Ocean</td>
                                        <td>Holocene</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td>Metallica</td>
                                        <td>72 Seasons</td>
                                        <td>21</td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td>Invent Animate</td>
                                        <td>Heavener</td>
                                        <td>19</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Katatonia</td>
                                        <td>Sky Void Of Stars</td>
                                        <td>19</td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td>Green Lung</td>
                                        <td>This Heathen Land</td>
                                        <td>18</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Zulu</td>
                                        <td>A New Tomorrow</td>
                                        <td>18</td>
                                    </tr>
                                    <tr>
                                        <td>15</td>
                                        <td>Currents</td>
                                        <td>The Death We Seek</td>
                                        <td>17</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Militarie Gun</td>
                                        <td>Life Under The Gun</td>
                                        <td>17</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Myrkur</td>
                                        <td>Spine</td>
                                        <td>17</td>
                                    </tr>
                                    <tr>
                                        <td>16</td>
                                        <td>Cattle Decapitation</td>
                                        <td>Terrasite</td>
                                        <td>16</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Kvelertak</td>
                                        <td>Endling</td>
                                        <td>16</td>
                                    </tr>
                                    <tr>
                                        <td>17</td>
                                        <td>Agriculture</td>
                                        <td>Agriculture</td>
                                        <td>15</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Distant</td>
                                        <td>Heritage</td>
                                        <td>15</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Hellripper</td>
                                        <td>Warlocks Grim &amp; Withered Hags</td>
                                        <td>15</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Queens Of The Stone Age</td>
                                        <td>In Times New Roman</td>
                                        <td>15</td>
                                    </tr>
                                    <tr>
                                        <td>16</td>
                                        <td>Code Orange</td>
                                        <td>The Above</td>
                                        <td>14</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Frozen Soul</td>
                                        <td>Glacian Domination</td>
                                        <td>14</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Guilt Trip</td>
                                        <td>Severance</td>
                                        <td>14</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Urne</td>
                                        <td>A Feast On Sorrow</td>
                                        <td>14</td>
                                    </tr>
                                    <tr>
                                        <td>17</td>
                                        <td>Beartooth</td>
                                        <td>The Surface</td>
                                        <td>13</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Bell Witch</td>
                                        <td>Future's Shadow Part 1: The Clandestine Gate</td>
                                        <td>13</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Conservative Military Image</td>
                                        <td>Casual Violence</td>
                                        <td>13</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Foo Fighters</td>
                                        <td>But Here We Are</td>
                                        <td>13</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>For I Am King</td>
                                        <td>Crown</td>
                                        <td>13</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Periphery</td>
                                        <td>Periphery V: Djent Is Not A Genre</td>
                                        <td>13</td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td>Mutoid Man</td>
                                        <td>Mutants</td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Tomb Mold</td>
                                        <td>The Enduring Spirit</td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <td>19</td>
                                        <td>Laster</td>
                                        <td>Andermans Mijne</td>
                                        <td>11</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Pest Control</td>
                                        <td>Don't Test The Pest</td>
                                        <td>11</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Portrayal Of Guilt</td>
                                        <td>Devil Music</td>
                                        <td>11</td>
                                    </tr>
                                    <tr>
                                        <td>20</td>
                                        <td>Blackbraid</td>
                                        <td>Blackbraid II</td>
                                        <td>10</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Enforced</td>
                                        <td>War Remains</td>
                                        <td>10</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Johnny Boot</td>
                                        <td>Moments Elsewhere</td>
                                        <td>10</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>King Gizzard &amp; The Lizard Wizard</td>
                                        <td>Petrodragonic Apocalypse; Or - Dawn Of Eternal Night: An Annihilation Of Planet Earth And The Beginning Of Merciless Damnation</td>
                                        <td>10</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Predatory Void</td>
                                        <td>Seven Keys To The Discomfort Of Being</td>
                                        <td>10</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Sylosis</td>
                                        <td>A Sign Of Things To Come</td>
                                        <td>10</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Wayfarer</td>
                                        <td>American Gothic</td>
                                        <td>10</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Year Of The Knife</td>
                                        <td>No Love Lost</td>
                                        <td>10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <div className="u-margin-b">
                            <h2>Top 10 beste shows</h2>

                            <div className="tablecontainer">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Band</th>
                                            <th>Aantal stemmen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>John Coffey </td>
                                            <td>14 </td>
                                        </tr>
                                        <tr>
                                            <td>Brutus </td>
                                            <td>8 </td>
                                        </tr>
                                        <tr>
                                            <td>Metallica </td>
                                            <td>5 </td>
                                        </tr>
                                        <tr>
                                            <td>Psychonaut </td>
                                            <td>5 </td>
                                        </tr>
                                        <tr>
                                            <td>Stray From The Path </td>
                                            <td>5 </td>
                                        </tr>
                                        <tr>
                                            <td>Gojira </td>
                                            <td>4 </td>
                                        </tr>
                                        <tr>
                                            <td>Hellripper </td>
                                            <td>4 </td>
                                        </tr>
                                        <tr>
                                            <td>Iron Maiden </td>
                                            <td>4 </td>
                                        </tr>
                                        <tr>
                                            <td>The Ocean </td>
                                            <td>4 </td>
                                        </tr>
                                        <tr>
                                            <td>Turnstile </td>
                                            <td>4</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="u-margin-b">
                            <h2>Top 15 leukste ontdekkingen</h2>

                            <div className="tablecontainer">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Band</th>
                                            <th>Aantal stemmen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Blood Abscission</td>
                                            <td>9 </td>
                                        </tr>
                                        <tr>
                                            <td>Better Lovers</td>
                                            <td>6 </td>
                                        </tr>
                                        <tr>
                                            <td>Pest Control</td>
                                            <td>4 </td>
                                        </tr>
                                        <tr>
                                            <td>Agriculture</td>
                                            <td>3 </td>
                                        </tr>
                                        <tr>
                                            <td>Dødheimsgard</td>
                                            <td>3 </td>
                                        </tr>
                                        <tr>
                                            <td>GEL</td>
                                            <td>3 </td>
                                        </tr>
                                        <tr>
                                            <td>Sleep Token</td>
                                            <td>3 </td>
                                        </tr>
                                        <tr>
                                            <td>Thantifaxath</td>
                                            <td>3 </td>
                                        </tr>
                                        <tr>
                                            <td>Broken Vow</td>
                                            <td>2 </td>
                                        </tr>
                                        <tr>
                                            <td>Guilt Trip</td>
                                            <td>2 </td>
                                        </tr>
                                        <tr>
                                            <td>Lowest Creature</td>
                                            <td>2 </td>
                                        </tr>
                                        <tr>
                                            <td>Many Eyes</td>
                                            <td>2 </td>
                                        </tr>
                                        <tr>
                                            <td>Mspaint</td>
                                            <td>2 </td>
                                        </tr>
                                        <tr>
                                            <td>Slope</td>
                                            <td>2 </td>
                                        </tr>
                                        <tr>
                                            <td>Spiritbox</td>
                                            <td>2 </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="u-margin-b">

                            <h2>Top 5 beste debuut</h2>

                            <div className="tablecontainer">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Band</th>
                                            <th>Album</th>
                                            <th>Aantal stemmen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Blood Abscission</td>
                                            <td>I</td>
                                            <td>9 </td>
                                        </tr>
                                        <tr>
                                            <td>Better Lovers </td>
                                            <td>God Made Me An Animal</td>
                                            <td>4 </td>
                                        </tr>
                                        <tr>
                                            <td>Agriculture </td>
                                            <td>Agriculture</td>
                                            <td>3 </td>
                                        </tr>
                                        <tr>
                                            <td>Pest Control </td>
                                            <td>Don't Test The Pest</td>
                                            <td>3 </td>
                                        </tr>
                                        <tr>
                                            <td>Predatory void </td>
                                            <td>Seven keys to the discomfort of being</td>
                                            <td>3</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
}

export default YearOverview;
