import { useState, useEffect } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import { chartColors } from "./chartColors";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    plugins: {
        legend: {
            display: false,
            position: "right"
        },
    },
    layout: {
        padding: {
            top: 0,
        }
    }
};

function FinalEpisode({ data, refresh }) {

    const [chartData, setChartData] = useState(null);

    useEffect(() => {

        if (refresh) {

            let pieData = data.reduce(function (sums, entry) {
                entry.tracks.map((track) => {
                    if (track.genre) {
                        sums[track.genre] = (sums[track.genre] || 0) + 1;
                    }
                    return track;
                })


                return sums;
            }, {});

            setChartData({
                maintainAspectRatio: true,
                responsive: true,
                labels: Object.keys(pieData),
                datasets: [
                    {
                        data: Object.values(pieData),
                        backgroundColor: chartColors,
                        hoverBackgroundColor: chartColors
                    }
                ]
            });

        }

    }, [data, refresh]);

    return (
        <div>
            <h2>Genres dit jaar</h2>

            <div className="min-height-400">

                {chartData &&
                    <Pie data={chartData} options={options} />
                }
            </div>
        </div>
    )
}

export default FinalEpisode;