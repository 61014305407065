import { useState, useEffect } from 'react';
import SeasonFilter from '../components/SeasonFilter/SeasonFilter.js';
import TypeFilter from '../components/TypeFilter/TypeFilter.js';
import FinalEpisode from '../components/FinalEpisode/FinalEpisode.js';
import PieChart from '../components/PieChart/PieChart';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async';

import { Link } from 'react-router-dom';

import data from '../data/new_data.json';

import './Home.css';

function Home() {

  const [seasons, setSeasons] = useState([]);
  const [currentYear, setCurrentYear] = useState("2024");
  const [filteredData, setFilteredData] = useState([]);
  const [episodeTypes, setEpisodeTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(false);
  const [finalEpisode, setFinalEpisode] = useState({});
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    setCurrentYear(currentYear);

    const newData = data.years;

    const years = [...new Set(Object.values(newData).map((item) => item.year))];

    setSeasons(years);

    var dataFilter = newData.filter(function (episode) {
      return episode.year === currentYear;
    });

    if (selectedType) {
      var filteredEps = dataFilter[0].episodes.filter((episode) => {
        return episode.type === selectedType;
      });
      // Don't update Pie, since it's focussed on yearly data and not filtered by type - data.
      setRefresh(false);
      setFilteredData(filteredEps);

    } else {
      setRefresh(true);
      setFilteredData(dataFilter[0].episodes);
    }

    setFinalEpisode(dataFilter[0].episodes[0]);
  }, [currentYear, selectedType]);

  // Go through the episodes for the current year and find unique types among them.
  // This is used to setup a filter list where you can filter episode types.
  useEffect(() => {
    let typeList = data.years.reduce(function (sums, entry) {

      if (entry.year === currentYear) {
        entry.episodes.map((episode, index) => {
          if (episode.type) {
            sums[episode.type] = (sums[episode.type] || 0) + 1;
          }
          return episode;
        });
      }

      return sums;
    }, []);


    setEpisodeTypes(Object.keys(typeList));
  }, [currentYear]);

  // function to reset selectedType to cancel filter when changing seasons
  const setCurrentYearFunc = (year) => {
    setSelectedType(false);
    setCurrentYear(year);
  };

  return (
    <div>
      <Helmet>
        <title>De Zes Losse Tanden Database - Home</title>
        <meta name="description" content="Interactieve Zes Losse Tanden data. Een overzicht met afleveringen per jaar, besproken tracks en genres." />
      </Helmet>

      <div className="app--container">

        <h1>Afleveringen in {currentYear}</h1>

        <SeasonFilter seasons={seasons} setyear={setCurrentYearFunc} currentYear={currentYear} />

        {filteredData.length &&
          <div className="card--grid">
            <PieChart data={filteredData} refresh={refresh} />
            <FinalEpisode episode={finalEpisode} />
          </div>
        }

        <h2>Alle afleveringen</h2>

        {episodeTypes.length > 1 &&
          <TypeFilter episodeTypes={episodeTypes} setSelectedType={setSelectedType} selectedType={selectedType} />
        }

        {filteredData.map((episode, index) => (
          <div key={index} className="episodecontainer">
            <h3>
              {episode.type === "ZLT" ? "Reguliere" : episode.type} aflevering {episode.type.toLowerCase() === "eindejaars" ? '' : `#${episode.episode_number}`}
              <span className="episode--link">
                {episode.spotify ? <a className="spotify--label" href={episode.spotify}>spotify</a> : <a href="https://petjeaf.com/zeslossetanden">🔒</a>}
              </span>
            </h3>

            <div className="tablecontainer">
              <table className="episodetable">
                <thead>
                  <tr>
                    <th width="16"></th>
                    <th>Band</th>
                    <th>Track</th>
                    <th>Album</th>
                    <th>Door</th>
                    <th>Genre</th>

                  </tr>
                </thead>
                <tbody>
                  {episode.tracks.map((track, index) =>
                    <tr key={index}>
                      <td>{track.artist_country &&
                        <LazyLoadImage
                          className="artist_flag"
                          alt={`Flag icon for ${track.artist_country}`}
                          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${track.artist_country}.svg`} />
                      }</td>
                      <td>{track.artist_name}</td>
                      <td>{track.track_name}</td>
                      <td>{track.album_name}</td>
                      <td><Link to={`/presenter/${track.who}`}>{track.who}</Link></td>
                      <td><Link to={`/genre/${track.genre}`}>{track.genre}</Link></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div >
    </div>
  );
}

export default Home;
