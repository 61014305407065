import './Over.css';
import { Helmet } from 'react-helmet-async';

function Over() {
    return (
        <div>
            <Helmet>
                <title>De Zes Losse Tanden Database - Over</title>
                <meta name="description" content="Informatie over hoe de Zes Losse Tanden database website tot stand is gekomen." />
            </Helmet>

            <div className="app--container about">

                <h1>Moi, even wat over deze website</h1>

                <p>
                    App met data uit de Zes Losse Tanden podcast. De data is verzameld door Sjefke/Güntwin, Erwin and yours truly, allen actief als leden van de Zes Losse Tanden Discord.
                </p>

                <h2>Rechten</h2>
                <p>Alle rechten met betrekking tot de naam (Zes Losse Tanden) en bijbehorende logo's en materialen zijn in handen van de podcast Zes Losse Tanden.</p>

                <p>Dank aan David Achter de Molen, Peter van der Ploeg, Gert-Jan van Aalst, en Smelly.</p>

                <h2>Petje.af</h2>

                <p>Zin in meer dan de reguliere afleveringen? Steun de podcast via <a href="https://petjeaf.com/zeslossetanden">Petje.af</a>.</p>

                <p>Vragen/opmerkingen/aanvullingen mogen via <a href="https://twitter.com/toffringa">Twitter</a> of de ZLT Discord.</p>
                <p>Gemaakt met ❤️ en ☕.</p>

                <p>Groetjes Tom</p>

            </div >
        </div>
    );

}

export default Over;
