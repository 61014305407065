import { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async';

import data from '../data/new_data.json';

function Presenter() {
  const { id } = useParams();

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    var trackList = [];

    data.years.map((year) => year.episodes.map((episode) => episode.tracks.map((track) => trackList.push(track))));

    trackList = trackList.filter(function (track) {
      return track.who === id;
    });

    trackList.sort((a, b) => (a.artist_name > b.artist_name) ? 1 : -1);

    setFilteredData(trackList);
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>De Zes Losse Tanden Database - Tracks meegenomen door {id}</title>
        <meta name="description" content={`Een overzicht met alle tracks meegenomen door ${id}`} />
      </Helmet>

      <div className="app--container">

        {filteredData.length === 0 &&
          <p>Er zijn geen bands gevonden voor deze presentator.</p>
        }

        {filteredData.length > 0 &&
          <>
            <h1>Bands meegenomen door {id}</h1>

            <div className="tablecontainer">

              <table>
                <thead>
                  <tr>
                    <th width="16"></th>
                    <th>Band</th>
                    <th>Track</th>
                    <th>Aflevering</th>
                    <th>Genre</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredData.map((episode, index) => (
                      <tr key={index}>
                        <td>{episode.artist_country &&
                          <LazyLoadImage
                            className="artist_flag"
                            alt={`Flag icon for ${episode.artist_country}`}
                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${episode.artist_country}.svg`} />
                        }</td>
                        <td>{episode.artist_name}</td>
                        <td>{episode.track_name}</td>
                        <td>{episode.id.toUpperCase()}</td>
                        <td><Link to={`/genre/${episode.genre}`}>{episode.genre}</Link></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>

            </div>
          </>
        }

      </div >
    </div>
  )
}

export default Presenter;