import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import Home from './pages/Home.js';
import Genre from './pages/Genre.js';
import Over from './pages/Over.js';
import Search from './pages/Search.js';
import Nav from './components/Nav/Nav.js';
import FunStats from './pages/FunStats.js';
import Presenter from './pages/Presenter.js';

import ReactGA from 'react-ga4';
import YearOverview from "./pages/YearOverview.js";

ReactGA.initialize('G-04V1TEYTT1');

function App() {

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  });


  return (
    <>
      <Nav />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/over" element={<Over />} />
        <Route path="/fun-stats" element={<FunStats />} />
        <Route path="/annual-best" element={<YearOverview />} />
        <Route path="/search" element={<Search />} />
        <Route path="/presenter/:id" element={<Presenter />} />
        <Route path="/genre/:id" element={<Genre />} />
      </Routes>
    </>
  );

}

export default App;
