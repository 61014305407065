import './TypeFilter.css';

export default function TypeFilter({ episodeTypes, setSelectedType, selectedType }) {
    return (
        <div className="filter--type">
            <button className={selectedType === false ? "active" : ""} onClick={() => setSelectedType(false)}>Alles</button>
            {episodeTypes.map((type, index) => (
                <button className={selectedType === type ? "active" : ""} key={index} onClick={() => setSelectedType(type)}>{type === "ZLT" ? "Regulier" : type}</button>
            ))}
        </div>
    )
}