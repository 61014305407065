import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async';

import data from '../data/new_data.json';

import './Search.css';

function Search() {

    const [search, setSearch] = useState('');
    const [tracks, setTracks] = useState([]);
    const [randomArtist, setRandomArtist] = useState("");
    const [sortOrder, setSortOrder] = useState(true);
    const [sortActive, setSortActive] = useState();

    useEffect(() => {
        var trackList = [];

        data.years.map((year) => year.episodes.map((episode) => episode.tracks.map((track) => trackList.push(track))));
        trackList.sort((a, b) => (a.artist_name > b.artist_name) ? 1 : -1);

        setTracks(trackList);
        setSortActive("artist_name");
        setRandomArtist(trackList[Math.round(Math.random() * (trackList.length - 0) + 0)].artist_name);
    }, []);


    const changeSorting = (type, sort) => {
        setSortActive(type);
        setSortOrder(sort);

        let newSort;

        if (sortOrder) {
            newSort = [...tracks].sort((a, b) => (a[type] > b[type]) ? -1 : 1);

        } else {
            newSort = [...tracks].sort((a, b) => (a[type] > b[type]) ? 1 : -1);
        }

        setTracks(newSort);
    };

    return (
        <div>
            <Helmet>
                <title>De Zes Losse Tanden Database - Zoeken...</title>
                <meta name="description" content="Zoekpagina met alle meegenomen tracks. Zoek hier op band, of sorteer op bandnaam of presentator." />
            </Helmet>
            <div className="app--container search">

                <h1>Zoeken</h1>

                <p>Gebruik de zoekfunctie om artiesten te zoeken</p>

                <label>Artiest:</label>

                <input type="text" placeholder={randomArtist} className="search--input" onChange={(e) => setSearch(e.target.value.toLowerCase())} />

                <div className="tablecontainer">
                    <table>
                        <thead>
                            <tr>
                                <th width="16"></th>
                                <th className="sortable" onClick={() => changeSorting('artist_name', !sortOrder)}>Band {sortActive === "artist_name" ? (sortOrder === true ? '⬆️' : '⬇️') : '↕️'}</th>
                                <th>Track</th>
                                <th>Album</th>
                                <th>Aflevering</th>
                                <th className="sortable" onClick={() => changeSorting('who', !sortOrder)}>Door {sortActive === "who" ? (sortOrder === true ? '⬆️' : '⬇️') : '↕️'}</th>
                                <th>Genre</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tracks.filter((item) => {
                                return search.toLowerCase() === '' ? item : item.artist_name.toLowerCase().includes(search)
                            }).map((episode, index) => (
                                <tr key={index}>
                                    <td>{episode.artist_country &&
                                        <LazyLoadImage
                                            className="artist_flag"
                                            alt={`Flag icon for ${episode.artist_country}`}
                                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${episode.artist_country}.svg`} />
                                    }</td>
                                    <td>{episode.artist_name}</td>
                                    <td>{episode.track_name}</td>
                                    <td>{episode.album_name}</td>
                                    <td>{episode.id.toUpperCase()}</td>
                                    <td><Link to={`/presenter/${episode.who}`}>{episode.who}</Link></td>
                                    <td><Link to={`/genre/${episode.genre}`}>{episode.genre}</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );

}

export default Search;
